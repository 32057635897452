import React from "react"

import {
  AppBar,
  Toolbar,
  Container,
  Hidden,
  makeStyles,
  withStyles,
  Box,
  useScrollTrigger,
  styled,
} from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "gatsby"

import YoutubeIcon from '../../images/youtube.svg'
import InstagramIcon from '../../images/instagram.svg'

const MinAppBar = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    borderTop: "1px solid #CFD8DC",
    borderBottom: "1px solid #CFD8DC",
  },
}))(AppBar)

export const SlimToolbar = withStyles(theme => ({
  root: {
    margin: 0,
    padding: `4px 0px`,
    minHeight: "48px",
  },
}))(Toolbar)

const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 1rem',
  '& a': {
    width: "24px",
    height: "24px",
  },
})

const SocialLinks = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginRight: "1rem",
  opacity: "0.75",
  gap: "1rem",
  '& a': {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    overflow: "hidden",
    '& img': {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
})

export default function BearAppBar({
  desktopBranding,
  desktopBrandingSmall,
  desktopCenter,
  mobileCenter,
  mobileBranding,
  desktopMenu,
  mobileMenu,
  rightCTA,
  leftCTA,
  isHome,
  ...rest
}) {
  const classes = useStyles()
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 120,
  })

  return (
    <MinAppBar {...rest} elevation={0}>
      <Container maxWidth="lg" className={classes.navbar}>
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          {mobileMenu && <Hidden lgUp>{mobileMenu}</Hidden>}
          <Hidden only={["md", "lg", "xl"]}>{mobileBranding}</Hidden>
          <Hidden only={["xs", "sm", "md"]}>{scrolled && mobileBranding}</Hidden>
        </Box>
        {desktopCenter && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <SearchContainer>
              <Link to="/search"><SearchIcon /></Link>
            </SearchContainer>
            <Hidden only={["xs", "sm"]} className={classes.desktopCenterNav}>
              {desktopCenter}
            </Hidden>
          </Box>
        )}
        <SlimToolbar className={classes.desktopCTA}>
          <SocialLinks>
            <a href="https://www.youtube.com/channel/UChUJiySdsgctUcHI2UtAtPw" target="_blank" rel="noreferrer">
              <img src={YoutubeIcon} />
            </a>
            <a href="https://www.instagram.com/diycosmetica/" target="_blank" rel="noreferrer">
              <img src={InstagramIcon} />
            </a>
          </SocialLinks>

          {rightCTA}
        </SlimToolbar>
      </Container>
    </MinAppBar>
  )
}

const useStyles = makeStyles(theme => ({
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div": {
      flex: "1 0 0",
    },
  },
  desktopCenterNav: {
    justifyContent: "center",
  },

  desktopCTA: {
    justifyContent: "flex-end",
  },
}))
