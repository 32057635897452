import React from "react"
import { Box } from "@material-ui/core"
import { Link } from "gatsby"
import logo from "../images/logonew.png"
import "./branding.css"

export default function Branding({ mobile }) {
  return (
    <Box
      color="text.primary"
      className="branding"
      style={{
        margin: mobile ? `inherit` : `1.10rem 0rem`,
        fontSize: mobile ? "1.15rem" : "0.45rem",
        fontWeight: 600,
        height: "97px",
      }}
    >
      <Link to="/" color="inherit" style={{ textDecoration: "none" }}>
        <img src={logo} alt="DIY Cosmetica" className="logosvg" />
      </Link>
    </Box>
  )
}
