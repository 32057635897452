// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-blog-query-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-minimal-blog-core/templates/blog-query.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-blog-query-tsx" */),
  "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-homepage-query-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-minimal-blog-core/templates/homepage-query.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-homepage-query-tsx" */),
  "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-page-query-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-minimal-blog-core/templates/page-query.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-page-query-tsx" */),
  "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-post-query-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-minimal-blog-core/templates/post-query.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-post-query-tsx" */),
  "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-tag-query-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-minimal-blog-core/templates/tag-query.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-minimal-blog-core-templates-tag-query-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-body-js": () => import("./../../../src/pages/body.js" /* webpackChunkName: "component---src-pages-body-js" */),
  "component---src-pages-face-js": () => import("./../../../src/pages/face.js" /* webpackChunkName: "component---src-pages-face-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-hair-js": () => import("./../../../src/pages/hair.js" /* webpackChunkName: "component---src-pages-hair-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-soap-js": () => import("./../../../src/pages/soap.js" /* webpackChunkName: "component---src-pages-soap-js" */)
}

