import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { CssBaseline, ThemeProvider } from "@material-ui/core"
import "./components/layout.css"
import Navbar from "./components/Navbar"
import theme, { GlobalCss } from "./theme"
import MarkdownStyles from "./shadow/markdown-styles"
import Footer from "./shadow/footer"
import Helmet from "react-helmet"

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Helmet>


          <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
          <link rel="manifest" href="/static/site.webmanifest" />
          <link rel="mask-icon" href="/static/safari-pinned-tab.svg" color="#c35eb3" />
          <meta name="msapplication-TileColor" content="#603cba" />
          <meta name="theme-color" content="#603cba" />


        </Helmet>
        <CssBaseline />
        <GlobalCss />
        <MDXProvider components={{ ...MarkdownStyles }}>
          <div style={{minHeight: "100%", display: "flex", flexFlow: "column"}}>
            <Navbar location={props.location} />
            <main style={{flexGrow: 1}}>{props.children}</main>
            <footer>
              <Footer />
            </footer>
          </div>
        </MDXProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}
