import React from "react"
import { Button, Tooltip, withStyles } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import MailChimp from "./mailchimp"

export default function CtaMain(props) {
  const [open, setOpen] = React.useState(false)
  
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          title={<MailChimp />}
          placement="bottom-end"
          leaveDelay={500}
          interactive
          arrow={true}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Button
            onClick={handleTooltipOpen}
            color="secondary"
            variant="contained"
          >
            Subscribe
          </Button>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  )
}

const LightTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.grey[100],
  },
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    background: "transparent",
    padding: 0,
    //fontSize: 11,
    maxWidth: 550,
  },
}))(Tooltip)
